import React from "react";

import AuthenticationForm from "@components/AuthenticationForm";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Text from "@components/Text";
import { useTranslation } from "react-i18next";
import TimeForNewVerify from "@authien/components/TimeForNewVerify";
import { t_SendRecoveryPasswordLink } from "@authien/i18n/usualTexts/t_recoveryPassword";
import { useAuthentication } from "@authien/contexts/AuthenticationContext";
import {
  CacheService,
  IDENTITY_POST_RESPONSE,
  PASSWORD_POST_RESPONSE,
} from "@services/cache/cacheService";
import { SendRecoveryPasswordLinkRequest } from "../services/OAuth2Request";

const SendRecoveryPasswordLinkPage: React.FC = () => {
  const { t } = useTranslation();

  const { authData } = useAuthentication();

  return (
    <AuthenticationForm
      title={t(t_SendRecoveryPasswordLink.title)}
      subtitle={t(t_SendRecoveryPasswordLink.subtitle)}
    >
      <Row className="my-2 py-1 text-center">
        <Col>
          <Text>
            {t(t_SendRecoveryPasswordLink.text, { email: authData.username })}
          </Text>
        </Col>
      </Row>

      <TimeForNewVerify
        sendCodeEmail={sendLinkEmail}
        timeLabel={t(t_SendRecoveryPasswordLink.timeLabel)}
        linkLabel={t(t_SendRecoveryPasswordLink.linkLabel)}
      />
    </AuthenticationForm>
  );

  async function sendLinkEmail() {
    console.log("send code email");
    const cache = new CacheService(authData, PASSWORD_POST_RESPONSE, authData);
    const identityPostResponse = cache.getToCache(IDENTITY_POST_RESPONSE);
    console.log(identityPostResponse);
    try {
      await SendRecoveryPasswordLinkRequest(
        authData.username ?? "",
        window.location.search,
        identityPostResponse?.access_token
      );
    } catch (error) {
      window.location.reload();
    }
  }
};

export default SendRecoveryPasswordLinkPage;
