import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import React, { ChangeEvent } from "react";

import "../assets/styles/animation.css";
import { Col, Row } from "react-bootstrap";
import TextButton from "./TextButton";
import { useTranslation } from "react-i18next";
import { t_help } from "@i18n/usualTexts/t_help";

const PREFIX = "showPasswordRadio-For";

const ShowPassword: React.FC<{
  name: string;
  nameconfirm: string;
  align: string;
}> = ({ name, nameconfirm, align = "left" }) => {
  const { t } = useTranslation();
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const type = checked ? "text" : "password";
    document.getElementById(`${name}`)?.setAttribute("type", type);
    if (nameconfirm)
      document.getElementById(`${nameconfirm}`)?.setAttribute("type", type);
  };

  return (
    <InputGroup
      className={
        "my-2" + (align === "right" ? "d-flex justify-content-end" : "")
      }
    >
      <Form.Check
        type="checkbox"
        label={t(t_help.showPasssowrdCheck)}
        id={`${PREFIX}${name}-ignore`}
        onChange={onChange}
      />
    </InputGroup>
  );
};

export interface FormFieldProps {
  name: string;
  nameconfirm?: string;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  autofocus?: boolean;
  immediateValidation?: boolean;
  errors?: Set<string>;
  required?: boolean;
  submitted?: boolean;
  forgotPasswordText?: string;
  forgotPasswordAction?: () => void;
  toPresentErrors?: boolean;
  children: string;
}

const FormField: React.FC<FormFieldProps> = ({
  name,
  nameconfirm = "",
  type = "text",
  onChange = () => {},
  value,
  autofocus = false,
  immediateValidation = true,
  errors = new Set(),
  required = false,
  submitted = false,
  forgotPasswordText = undefined,
  forgotPasswordAction = undefined,
  toPresentErrors = true,
  children,
}) => {
  const typeNow = document.getElementById(name)?.getAttribute("type")

  const { t } = useTranslation();
  const requiredErrorMessage = t(t_help.fieldOrSelect, {placeholder: children});

  if (submitted && !value && required) {
    errors.clear();
    errors.add(requiredErrorMessage);
  } else if (value){
    errors.delete(requiredErrorMessage);
  }

  return (
    <div className="form-floating">
      <input
        className={`form-control ${
          errors.size ? "is-invalid" : "" 
        }`}
        placeholder={children}
        autoFocus={autofocus}
        required={required}
        data-immediate-validation={immediateValidation}
        name={name}
        id={name}
        type={type}
        value={value}
        onChange={onChange}
      />

      <label className="form-label" htmlFor={name}>
        {children}
      </label>

      {errors.size > 0 &&
        toPresentErrors &&
        [...errors].map((error) => (
          <div key={error} data-testid="spanElement" className={"invalid-feedback d-block" + (submitted ? " shake-animation" : "")}>
            {error}
          </div>
        ))}

      {(forgotPasswordText || type === "password") && (
        <Row className="py-2 px-0 m-0 ">
          {forgotPasswordText && (
            <Col className="p-0 m-0 d-flex align-items-center">
              <TextButton onClick={forgotPasswordAction}>
                {forgotPasswordText}
              </TextButton>
            </Col>
          )}
          {type === "password" && (
            <>
              {nameconfirm && typeNow &&
                document
                  .getElementById(`${nameconfirm}`)
                  ?.setAttribute("type", typeNow)}
              <Col className="p-0 m-0 d-flex align-items-center">
                <ShowPassword
                  name={name}
                  nameconfirm={nameconfirm}
                  align={forgotPasswordText ? "right" : "left"}
                />
              </Col>
            </>
          )}
        </Row>
      )}
    </div>
  );
};

export default FormField;
