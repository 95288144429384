import { AccountType } from "@contexts/AccountContext";
import { currentTimestamp } from "@helpers/delay";
import { isSuccessTokenStepResponse } from "@helpers/isSuccessTokenStepResponse";
import { ApiError, RedirectionResponse, SuccessTokenStepResponse } from "@services/auth";

import cache from "memory-cache";
import sha256 from "crypto-js/sha256";
import { AuthType } from "@contexts/AuthenticationContext";

export const PERSONAL_INFO_POST_RESPONSE = "personalInfoPostResponse";
export const IDENTITY_POST_RESPONSE = "identityPostResponse";
export const PASSWORD_POST_RESPONSE = "passwordPostResponse";

const POST_RESPONSE_LIST = [PERSONAL_INFO_POST_RESPONSE, IDENTITY_POST_RESPONSE, PASSWORD_POST_RESPONSE];

export interface CachedResponse {
  personalInfoPostResponse?: ApiError | SuccessTokenStepResponse;
  identityPostResponse?: ApiError | SuccessTokenStepResponse;
  passwordPostResponse?: ApiError | SuccessTokenStepResponse;
}

type Post_type =
  | typeof PERSONAL_INFO_POST_RESPONSE
  | typeof IDENTITY_POST_RESPONSE
  | typeof PASSWORD_POST_RESPONSE;

export function calculateHash(value: string): string {
  const hashed_value = sha256(value).toString();
  return hashed_value;
}

export class CacheService {
  post_type: Post_type;

  hash_key: string;

  cachedResponse: CachedResponse = {
    personalInfoPostResponse: undefined,
    identityPostResponse: undefined,
    passwordPostResponse: undefined,
  };

  constructor(currentDatas: AccountType | AuthType, post_type: Post_type, accountData: AccountType | AuthType) {
    this.post_type = post_type;

    const { step, ...accountDatas } = currentDatas;
    this.hash_key = calculateHash(JSON.stringify(accountDatas));

    if (cache.get(this.hash_key) === null) this.createCache(this.hash_key, accountData);
    else this.cachedResponse = cache.get(this.hash_key);
  }

  createCache(hash_key: string, accountData: AccountType | AuthType) {
    const { step, ...accountDatas } = accountData;
    const previous_data = cache.get(calculateHash(JSON.stringify(accountDatas)));
    const valid_types = POST_RESPONSE_LIST.slice(0, POST_RESPONSE_LIST.indexOf(this.post_type));
    
    if (previous_data) valid_types.forEach((type) => {
      this.cachedResponse[type as keyof CachedResponse] = previous_data[type];
    });

    cache.put(hash_key, this.cachedResponse, 420000);
  }

  postToCache(requestResponse: ApiError | SuccessTokenStepResponse | RedirectionResponse) {
    this.cachedResponse = {
      ...this.cachedResponse,
      [this.post_type]: requestResponse,
    };

    cache.put(this.hash_key, this.cachedResponse, 420000);
  }

  getToCache(post_type: Post_type = this.post_type) {
    const response = cache.get(this.hash_key);
    
    if (response === null || !response[post_type]) return undefined;

    const requestResponse = response[post_type];
    if (isSuccessTokenStepResponse(requestResponse)) {
      if (requestResponse.expiration_time > currentTimestamp())
        return requestResponse;
      else return undefined;
    }

    throw response[this.post_type];
  }
}

