import React, { useState, ChangeEvent } from "react";

import { AuthType, useAuthentication } from "@contexts/AuthenticationContext";
import { useOAuth2 } from "@contexts/OAuth2Context";

import { NavigateFunction, useNavigate } from "react-router-dom";

import AuthenticationForm from "@components/AuthenticationForm";
import FormField from "@components/Field";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import PagePaths from "@authien/helpers/constants/PagePaths";
import {
  ComposeURLWithGetQueryArguments,
  ComposeURLWithResponseParameters,
} from "@helpers/get";

import "bootstrap-icons/font/bootstrap-icons.css";
import {
  PasswordRequest,
  SendRecoveryPasswordLinkRequest,
} from "../services/OAuth2Request";
import { showAuthError } from "../services/OAuth2ShowError";
import OAuth2Service from "../services/OAuth2Service";
import { isSuccessTokenStepResponse } from "@helpers/isSuccessTokenStepResponse";
import { delay, intervalMiliseconds } from "@helpers/delay";
import { useTranslation } from "react-i18next";
import { t_password } from "@i18n/usualTexts/t_password";
import {
  cleanFormDataErrors,
} from "@helpers/FormDataServices";
import {
  CacheService,
  IDENTITY_POST_RESPONSE,
  PASSWORD_POST_RESPONSE,
} from "@services/cache/cacheService";
import { setState } from "@authien/helpers/state_storage";
import { SuccessTokenStepResponse } from "@authien/services/auth";

interface FormData {
  password: {
    value: string;
    errors: Set<string>;
  };
}

const PasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { authData, setAuthData } = useAuthentication();
  const { oauth2Data, state } = useOAuth2();
  const [formData, setFormData] = useState<FormData>({
    password: {
      value: "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title={t(t_password.title, { username: authData.username })}
      subtitle={t(t_password.subtitle)}
      back={back}
      onSubmit={() => {
        handleSubmit();
      }}
      isLoading={isLoading}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="password"
            type="password"
            value={formData.password.value}
            onChange={handleFormData}
            forgotPasswordText={t(t_password.forgotPasswordLink)}
            forgotPasswordAction={() =>
              toSendRecoveryPasswordLinkPage(navigate)
            }
            errors={formData.password.errors}
            required={true}
            submitted={submitted}
          >
            {t(t_password.passwordPlaceholder)}
          </FormField>
        </Col>
      </Row>
    </AuthenticationForm>
  );

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const { value } = e.target;
    formData.password.value = value;
    setFormData({ ...formData });
    cleanFormDataErrors(formData);
  }

  function back() {
    setAuthData({
      ...authData,
      step: 1,
    });

    navigate(ComposeURLWithGetQueryArguments(PagePaths.LOGIN.IDENTITY));
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    const step = 3;

    const newAccountData: AuthType = {
      ...authData,
      step: step,
    };

    try {
      const authenticationService: OAuth2Service = new OAuth2Service();
      authenticationService.checkPasswordFormat(formData.password.value);

      await postFormDatas_and_navigate(newAccountData);
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      showAuthError(
        "password",
        error,
        setServerErrorPopUp,
        formData.password.errors,
        t
      );
    } finally {
      setIsLoading(false);

      setFormData({
        ...formData,
      });

      await delay(1000);
      setSubmitted(false);
    }
  }

  async function postFormDatas_and_navigate(newAuthData: AuthType) {
    const cache = new CacheService(
      newAuthData,
      PASSWORD_POST_RESPONSE,
      authData
    );
    const identityPostResponse = cache.getToCache(IDENTITY_POST_RESPONSE);

    const intervalMilisecondsValue = intervalMiliseconds(identityPostResponse);
    await delay(intervalMilisecondsValue);

    const passwordPostResponse = await PasswordRequest(
      oauth2Data,
      formData.password.value,
      identityPostResponse?.access_token
    );
    console.log(passwordPostResponse);

    if (!isSuccessTokenStepResponse(passwordPostResponse)) {
      await delay(intervalMilisecondsValue);

      setState(state);
      window.location.href =
        ComposeURLWithResponseParameters(passwordPostResponse);
    } else {
      cache.postToCache(passwordPostResponse);
      setAuthData(newAuthData);
      navigate(
        ComposeURLWithGetQueryArguments(PagePaths.LOGIN.VERIFYEMAIL)
      );
    }
  }

  async function toSendRecoveryPasswordLinkPage(navigate: NavigateFunction) {
    setIsLoading(true);
    try {

      const identityPostResponse = await UseIdentityResponse();

      await SendRecoveryPasswordLinkRequest(
        authData.username ?? "",
        window.location.search,
        identityPostResponse?.access_token
      );

      setAuthData({
        ...authData,
        step: 4,
      });

      navigate(
        ComposeURLWithGetQueryArguments(
          PagePaths.LOGIN.SENDRECOVERYPASSWORDLINK
        ),
        {
          replace: true,
        }
      );
    } catch (e) {
      window.location.reload();
    } finally {
      setIsLoading(false);
    }
  }

  async function UseIdentityResponse(): Promise<SuccessTokenStepResponse | undefined> {
    const cache = new CacheService(
      authData,
      PASSWORD_POST_RESPONSE,
      authData
    );
    const identityPostResponse = cache.getToCache(IDENTITY_POST_RESPONSE);
  
    const intervalMilisecondsValue = intervalMiliseconds(identityPostResponse);
    await delay(intervalMilisecondsValue);
  
    return identityPostResponse
  }
};


export default PasswordPage;
