/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticationSecondFactorValidationRequest } from '../models/AuthenticationSecondFactorValidationRequest';
import type { AuthRecoveryPasswordPost } from '../models/AuthRecoveryPasswordPost';
import type { AuthSendRecoveryPasswordLinkPost } from '../models/AuthSendRecoveryPasswordLinkPost';
import type { AuthVerifyEmailRequest } from '../models/AuthVerifyEmailRequest';
import type { IdentityAuthenticationRequest } from '../models/IdentityAuthenticationRequest';
import type { PasswordAuthenticationRequest } from '../models/PasswordAuthenticationRequest';
import type { RedirectionResponse } from '../models/RedirectionResponse';
import type { StartTransactionRequest } from '../models/StartTransactionRequest';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { SuccessTokenStepResponse } from '../models/SuccessTokenStepResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserAuthenticationService {

    /**
     * Start an authentication transaction
     * It starts an authentication transaction based on OAuth2 client and device information. In near future this endpoint will be moved to an isolated host to prevent DDoS attacks in a better way.
     * @param requestBody 
     * @returns SuccessTokenStepResponse Successful validation
     * @throws ApiError
     */
    public static startAuthenticationPost(
requestBody: StartTransactionRequest,
): CancelablePromise<SuccessTokenStepResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user/start_authentication',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Validate user identity
     * Validate user identity. It is required an Authorization token received from a request of `/auth/user/start_authorization`. Returned JWT token contains the `sub` claims populated with the user identity to be used in the next steps.
 * 
     * @param requestBody JSON payload with user identity and transaction token.
     * @returns SuccessTokenStepResponse Successful validation
     * @throws ApiError
     */
    public static identityPost(
requestBody: IdentityAuthenticationRequest,
): CancelablePromise<SuccessTokenStepResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user/identity',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Validate user Password
     * Endpoint to validate user password after validating identity. It is required a valid token received from a request of `/auth/user/identity`.
     * @param requestBody 
     * @returns any Password validated successfully.
     * @throws ApiError
     */
    public static passwordPost(
requestBody: PasswordAuthenticationRequest,
): CancelablePromise<(SuccessTokenStepResponse | RedirectionResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Validate second factor
     * Validate second-factor authentication for a user. It is required a Authorization token received from a request of `/oauth2/user/password`.
 * 
     * @param requestBody 
     * @returns RedirectionResponse Second factor enabled successfully.
     * @throws ApiError
     */
    public static secondFactorPost(
requestBody: AuthenticationSecondFactorValidationRequest,
): CancelablePromise<RedirectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user/second_factor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send link to user's email to recover user's password
     * Endpoint to send link for recovery user passsword. It dependents on the token generated in the previous step `/auth/user/identity`. It send a link to the user e-mail that should be used to create a new password.
     * @param requestBody 
     * @returns SuccessTokenStepResponse Transaction step completed successfully.
     * @throws ApiError
     */
    public static authSendRecoveryPasswordLinkPost(
requestBody: AuthSendRecoveryPasswordLinkPost,
): CancelablePromise<SuccessTokenStepResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user/send_recovery_password_link',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Recovery user's passsword
     * Endpoint to recovery user passsword. It dependents on the token generated in the previous step `/auth/user/send_recovery_password_link`. It post the new password.
     * @param requestBody 
     * @returns SuccessTokenStepResponse Transaction step completed successfully.
     * @throws ApiError
     */
    public static authRecoveryPasswordPost(
requestBody: AuthRecoveryPasswordPost,
): CancelablePromise<SuccessTokenStepResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user/recovery_password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Verify User's email
     * Endpoint to verify the user email after completing all the required login steps.
     * @param requestBody 
     * @returns SuccessResponse User registration committed successfully. User has been created.
     * @throws ApiError
     */
    public static authVerifyEmailRequest(
requestBody: AuthVerifyEmailRequest,
): CancelablePromise<RedirectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user/verify_email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Complete authentication process
     * This endpoint completes the OAuth 2.0 authentication process. It is responsible for redirecting valid users to the client endpoint.
     * @param code Success authentication code
     * @param responseType The type of response desired from the authorization server
     * @param clientId The client identifier as registered in the authorization server
     * @param redirectUri URI to redirect the user to after authorization
     * @param scope Space-delimited list of scopes
     * @param state Opaque value used to maintain state between request and callback
     * @returns void 
     * @throws ApiError
     */
    public static authenticateGet(
code: string,
responseType: 'code',
clientId: string,
redirectUri: string,
scope?: string,
state?: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/user/authenticate',
            query: {
                'code': code,
                'response_type': responseType,
                'client_id': clientId,
                'redirect_uri': redirectUri,
                'scope': scope,
                'state': state,
            },
            errors: {
                302: `Redirect to client application with authorization code or error`,
            },
        });
    }

}
