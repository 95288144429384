export function nextInput(event) {
  const inputs = Array.from(document.getElementsByTagName("input"));
  const index = inputs.indexOf(document.activeElement);
  let nextIndex = nextIndexFunction(index, inputs)
  if (inputs[nextIndex].id.includes("ignore")) nextIndex = nextIndexFunction(nextIndex, inputs);
  if (event && nextIndex !== 0) event.preventDefault();
  inputs[nextIndex].focus();

}

function nextIndexFunction(index, inputs) {
  return index === inputs.length - 1 ? 0 : index + 1;
}
