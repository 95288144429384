import React from "react";
import { Route, Routes } from "react-router-dom";

import { AuthenticationProvider } from "@contexts/AuthenticationContext";
import { stepsAuthentication, stepsCreateAccount } from "@authien/flowConfig";

import { OAuth2Provider } from "@contexts/OAuth2Context";
import { AccountProvider } from "@contexts/AccountContext";
import CongratulationsPage from "@features/createaccount/page/CongratulationsPage";
import { RenderOAuth2 } from "@contexts/render/RenderOAuth2";
import { RenderAuthentication } from "@contexts/render/RenderAuthentication";
import { RenderAccount } from "@contexts/render/RenderAccount";
import OnBoardingPage from "./features/createaccount/page/OnBoarding";
import { RenderOnBoarding } from "./contexts/render/RenderOnBoarding";
import PagePaths from "./helpers/constants/PagePaths";
import ServiceTermsVersion1 from "./pages/ServiceTerms/Version1";


function App() {
  return (
    <OAuth2Provider>
      <RenderOAuth2>
        <Authentication />
        <CreateAccount />
      </RenderOAuth2>
      <OnBoarding />
      <ServiceTerms />
      {/* <Routes>
        <Route
          key={1}
          path={PagePaths.SERVICETERMS}
          element={<ServiceTermsPage11 />}
        />
      </Routes> */}
    </OAuth2Provider>
  );
}

export default App;

function Authentication() {
  return (
    <AuthenticationProvider>
      <RenderAuthentication>
        <Routes>
          {stepsAuthentication.map((step) => (
            <Route
              key={step.stepNumber}
              path={step.page}
              element={step.element}
            />
          ))}
        </Routes>
      </RenderAuthentication>
    </AuthenticationProvider>
  );
}

function CreateAccount() {
  return (
    <AccountProvider>
      <RenderAccount>
        <Routes>
          {stepsCreateAccount.map((step) => (
            <Route
              key={step.stepNumber}
              path={step.page}
              element={step.element}
            />
          ))}
          <Route
            key={5}
            path={PagePaths.SIGNUP.CONGRATULATIONS}
            element={<CongratulationsPage />}
          />
        </Routes>
      </RenderAccount>
    </AccountProvider>
  );
}

function OnBoarding() {
  return (
    <RenderOnBoarding>
      <Routes>
        <Route
          key={1}
          path={PagePaths.ONBOARDING}
          element={<OnBoardingPage />}
        />
      </Routes>
    </RenderOnBoarding>
  );
}

function ServiceTerms() {
  const versions = [<ServiceTermsVersion1 />];
  return (
    <Routes>
      {versions.map((version, index) => (
        <Route
          key={index + 1}
          path={`${PagePaths.SERVICETERMS}/v${index + 1}`}
          element={version}
        />
      ))}
      <Route path={`${PagePaths.SERVICETERMS}/*`} element={versions[versions.length - 1]} />
    </Routes>
  );
}
