import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthentication } from "../AuthenticationContext";
import PagePaths from "@authien/helpers/constants/PagePaths";
import { ComposeURLWithGetQueryArguments } from "@helpers/get";
import { ExtractParameter } from "../OAuth2Context";
import { OAuth2QueryParameters } from "@authien/features/authentication/constants/Oauth2";
import { verifyCode } from "./RenderOnBoarding";

type RenderAuthenticationProps = { children: ReactNode };

export const RenderAuthentication = ({ children }: RenderAuthenticationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authData } = useAuthentication();

  const [isValidCode, setIsValidCode] = useState(true);

  async function defineRender() {
    const currentPage = location.pathname;

    const stepPageMapping: { [key: number]: string } = {
      1: PagePaths.LOGIN.IDENTITY,
      2: PagePaths.LOGIN.PASSWORD,
      3: PagePaths.LOGIN.VERIFYEMAIL,
      4: PagePaths.LOGIN.SENDRECOVERYPASSWORDLINK,
      5: PagePaths.LOGIN.RECOVERYPASSWORD,
    };

    let currentStep = authData.step ?? 1;
    if (!currentPage.startsWith("/login/")) return;

    if (currentPage === PagePaths.LOGIN.RECOVERYPASSWORD) {
      currentStep = 5;
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get(OAuth2QueryParameters.CODE) || "";
      const isValidCode = await verifyCode(code);
      if (!isValidCode) setIsValidCode(false);
    } else if (currentPage !== stepPageMapping[currentStep]) {
      console.log(currentPage, currentStep)
      navigate(ComposeURLWithGetQueryArguments(stepPageMapping[1]));
    }

    navigate(ComposeURLWithGetQueryArguments(stepPageMapping[currentStep]));
  } 
  
  useEffect(() => {
    defineRender();
  }, [authData.step, authData.username, navigate, location.pathname]);

  if (!isValidCode) return <h3>Invalid Code</h3>; 

  return <>{children}</>;
};
