import React, { FormEventHandler, ReactNode, useEffect, useRef } from "react";

import { Container, Row, Col, Form } from "react-bootstrap";

import K4DFormHeader from "@authien/layouts/K4DFormHeader";

import ClientLogo from "./ClientLogo";

import "../assets/styles/base.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TextButton from "./TextButton";
import ServerErrorPopUp from "./ServerErrorPopUp";
import { useTranslation } from "react-i18next";
import { t_help } from "@i18n/usualTexts/t_help";
import ReCAPTCHA from "react-google-recaptcha";
import reCaptchaRequest from "@helpers/reCaptchaRequest";
import { nextInput } from "@helpers/nextInput";
import NextButton from "./button/Next";

interface LoginContainerProps {
  title?: string;
  subtitle?: string;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  children: ReactNode;
  back?: Function;
  isLoading?: boolean;
  serverErrorPopUp?: string | null;
  setServerErrorPopUp?: React.Dispatch<React.SetStateAction<string | null>>;
}

const REACT_APP_RECAPTCHA_KEY: string =
  process.env.REACT_APP_RECAPTCHA_KEY ?? "";

const AuthenticationForm: React.FC<LoginContainerProps> = ({
  title = null,
  subtitle = null,
  children,
  onSubmit = () => {},
  back,
  isLoading,
  serverErrorPopUp,
  setServerErrorPopUp,
}) => {
  const { t } = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const REACT_APP_RECAPTCHA_ON = process.env.REACT_APP_RECAPTCHA_ON !== "false";

  const preventDefault: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      if (REACT_APP_RECAPTCHA_ON) {
        const token = await recaptchaRef.current?.executeAsync();
        if (token) await reCaptchaRequest(token.toString(), "REQUEST");
      }
      onSubmit(e);

    } catch (error) {
      window.location.reload();
    }
  };

  useEffect(() => {
    const handleEscPress = (event: KeyboardEvent) => {
      if (
        !event.shiftKey &&
        event.key === "Enter" &&
        document.activeElement?.tagName !== "BUTTON" &&
        document.activeElement?.tagName !== "A" &&
        document.activeElement?.role !== "listbox"
      ) {
        nextInput(event);
      }
      if (event.key === "Escape" && back) {
        back();
      }
    };

    document.addEventListener("keydown", handleEscPress);

    return () => {
      document.removeEventListener("keydown", handleEscPress);
    };
  }, []);

  return (
    <Container
      fluid
      className="p-0"
      style={{
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? "none" : "auto",
      }}
    >
      <Row
        className="justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Col
          md={12}
          lg={6}
          xl={4}
          className="p-0 border border-1 rounded border-xs-none slide-from-right"
          style={{ maxWidth: "448px" }}
        >
          <Container fluid className="p-2 m-0">
            <Row>
              <Col>
                <K4DFormHeader />
              </Col>
            </Row>
            <Row>
              <Col>
                <ClientLogo />
              </Col>
            </Row>
            {(title || subtitle) && (
              <Row>
                <Col className="text-center p-3 px-3">
                  {title && (
                    <p
                      style={{
                        fontSize: "24px",
                        margin: 0,
                        fontWeight: 490,
                        wordBreak: "break-word",
                        marginBottom: subtitle ? 0 : "43px",
                      }}
                    >
                      {title}
                    </p>
                  )}
                  {subtitle && (
                    <p
                      style={{
                        fontSize: "18px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {subtitle}
                    </p>
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Form
                  noValidate={true}
                  className="m-0 px-4 mb-2"
                  style={{ minHeight: "280px" }}
                  onSubmit={preventDefault}
                >
                  <Container className="p-0">
                    {children}
                    {back && (
                      <Row className="flex-grow-1 text-center text-lg-start mt-5">
                        <Col xs={6} className="text-start">
                          <TextButton
                            variant="secondary"
                            onClick={() => back()}
                          >
                            {t(t_help.backButton)}
                          </TextButton>
                        </Col>
                        <Col xs={6} className="text-end align-items-end">
                          <NextButton isLoading={isLoading} />
                        </Col>
                      </Row>
                    )}
                  </Container>
                </Form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {serverErrorPopUp && setServerErrorPopUp && (
        <ServerErrorPopUp
          errorData={serverErrorPopUp}
          setErrorData={setServerErrorPopUp}
        />
      )}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={REACT_APP_RECAPTCHA_KEY}
        size="invisible"
      />
    </Container>
  );
};

export default AuthenticationForm;
