import { OAuth2Type } from "@contexts/OAuth2Context";
import { getDeviceInfo } from "@helpers/getDeviceInfo";
import {
  OpenAPI,
  ProblemResponse,
  RedirectionResponse,
  SuccessTokenStepResponse,
  UserAuthenticationService,
} from "@services/auth";

export async function StartAuthenticationRequest(
  oauth2Data: OAuth2Type
): Promise<SuccessTokenStepResponse> {
  const startTransactionDatas = {
    device_info: await getDeviceInfo(),
    client: oauth2Data,
  };

  OpenAPI.TOKEN = undefined;

  const response = await UserAuthenticationService.startAuthenticationPost(
    startTransactionDatas
  );

  return response;
}

export async function IdentityRequest(
  oauth2Data: OAuth2Type,
  username: string,
  token: string | undefined
): Promise<SuccessTokenStepResponse> {
  const identityAuthenticationRequestDatas = {
    identity: username,
    client: oauth2Data,
  };

  OpenAPI.TOKEN = token;

  const response = await UserAuthenticationService.identityPost(
    identityAuthenticationRequestDatas
  );

  return response;
}

export async function PasswordRequest(
  oauth2Data: OAuth2Type,
  password: string,
  token: string | undefined
): Promise<SuccessTokenStepResponse | RedirectionResponse> {
  const passwordAuthenticationRequestDatas = {
    password: password,
    client: oauth2Data,
  };

  OpenAPI.TOKEN = token;

  const response = await UserAuthenticationService.passwordPost(
    passwordAuthenticationRequestDatas
  );

  return response;
}

export async function SendRecoveryPasswordLinkRequest(
  email: string,
  state: string,
  token: string | undefined
): Promise<SuccessTokenStepResponse> {
  const recoveryPasswordLinkRequestDatas = {
    identity: email,
    state: state,
  };

  OpenAPI.TOKEN = token;

  const response =
    await UserAuthenticationService.authSendRecoveryPasswordLinkPost(
      recoveryPasswordLinkRequestDatas
    );

  console.log("send link");

  return response;
}

export async function RecoveryPasswordRequest(
  password: string,
  passwordConfirmation: string,
  token: string | undefined
): Promise<SuccessTokenStepResponse> {
  const recoveryPasswordDatas = {
    password: password,
    password_confirmation: passwordConfirmation,
  };

  OpenAPI.TOKEN = token;

  const response = await UserAuthenticationService.authRecoveryPasswordPost(
    recoveryPasswordDatas
  );

  return response;
}

export async function VerifyEmailPost(
  confirmationCode: string,
  oauth2Data: OAuth2Type,
  token: string | undefined
): Promise<RedirectionResponse> {
  const commitDatas = {
    confirmation_code: parseInt(confirmationCode),
    client: oauth2Data,
  };

  OpenAPI.TOKEN = token;

  const response = await UserAuthenticationService.authVerifyEmailRequest(
    commitDatas
  );

  return response;
}
