/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoogleAuthenticationCommitRequest } from '../models/GoogleAuthenticationCommitRequest';
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { RedirectionResponse } from '../models/RedirectionResponse';

export class GoogleAuthenticationService {

    /**
     * OAuth2 Google authentication callback
     * This endpoints is used for success oauth2 authentication. Using Code response type, it receives an authorization code for client getting authentication token.
     * @param code 
     * @returns void 
     * @throws ApiError
     */
    public static getGoogleOAuth2AuthorizationCallback(
code: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oauth2/oidc/google/authorized',
            query: {
                'code': code,
            },
            errors: {
                302: `User is authorized and redirect to the client application (e.g., Housien, Lifien).`,
            },
        });
    }

    /**
     * Google Login
     * Endpoint for initiating OAuth2 Google login.
     * @returns void 
     * @throws ApiError
     */
    public static getGoogleOAuth2Login(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oauth2/oidc/google/login',
            errors: {
                302: `Redirect to Google login page.`,
            },
        });
    }

    /**
     * Commit Google Login new user
     * Endpoint to commit the user registration after completing all the Google OAuth2 Login steps.
     * @param requestBody 
     * @returns SuccessResponse User registration committed successfully. User has been created.
     * @throws ApiError
     */
    public static googleOAuth2CommitPost(
requestBody: GoogleAuthenticationCommitRequest,
): CancelablePromise<RedirectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/oauth2/oidc/google/commit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
