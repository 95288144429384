import React from "react";

type ErrorPageProps = {
  missingArguments?: string[]; // Define que missingArguments é opcional e um array de strings
};

const ErrorPage: React.FC<ErrorPageProps> = ({ missingArguments = [] }) => {
  return (
    <>
      <h3>Page Not Found</h3>
      {missingArguments.length > 0 && (
        <p>
          The following arguments are required: {missingArguments.join(", ")}.
        </p>
      )}
    </>
  );
};

export default ErrorPage;