import React, { useState, ChangeEvent } from "react";

import { AccountType, useAccount } from "@contexts/AccountContext";

import { useNavigate } from "react-router-dom";

import AuthenticationForm from "@components/AuthenticationForm";
import FormField from "@components/Field";

import AccountService from "../service/AccountService";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ComposeURLWithGetQueryArguments } from "@helpers/get";
import PagePaths from "@authien/helpers/constants/PagePaths";
import TimeForNewVerify from "@components/TimeForNewVerify";
import { useTranslation } from "react-i18next";
import { t_insertUsernameCode } from "@i18n/usualTexts/t_insertUsernameCode";
import {
  cleanFormDataErrors,
  cleanStringsObject,
} from "@helpers/FormDataServices";
import { CacheService, PASSWORD_POST_RESPONSE } from "@services/cache/cacheService";
import { delay, intervalMiliseconds } from "@helpers/delay";
import { RegisterCommitPost, SendConfirmationCodePost } from "../service/AccountRequest";
import { showAuthError } from "../service/RegisterShowError";

interface FormData {
  usernamecode: {
    value: string;
    errors: Set<string>;
  };
}

const InsertUsernameCodePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { accountData, setAccountData } = useAccount();
  const [formData, setFormData] = useState<FormData>({
    usernamecode: {
      value: "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title={t(t_insertUsernameCode.title)}
      subtitle={t(t_insertUsernameCode.subtitle)}
      back={back}
      onSubmit={() => handleSubmit()}
      isLoading={isLoading}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="usernamecode"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.usernamecode.value}
            errors={formData.usernamecode.errors}
            submitted={submitted}
          >
            {t(t_insertUsernameCode.codePlaceholder)}
          </FormField>
        </Col>
      </Row>

      <TimeForNewVerify sendCodeEmail={sendCodeEmail} />
    </AuthenticationForm>
  );

  async function sendCodeEmail() {
    console.log("send code email");
    const cache = new CacheService(
      accountData,
      PASSWORD_POST_RESPONSE,
      accountData
    );
    const passwordPostResponse = cache.getToCache();
    console.log(passwordPostResponse);
    try {
      await SendConfirmationCodePost(passwordPostResponse?.access_token);
    } catch (error) {
      window.location.reload();
    }
  }

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    formData[name as keyof FormData].value = value;
    setFormData({
      ...formData,
    });
  }

  function back() {
    setAccountData({
      ...accountData,
      step: 4,
    });

    navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.CREATEPASSWORD));
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    const step = 6;

    const newAccountData: AccountType = {
      ...accountData,
      step: step,
    };

    try {
      checkThisFormData()

      await post_and_navigate(newAccountData);
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      console.log(error)
      showAuthError(error, setServerErrorPopUp, frontExceptionFunction, t);
    } finally {
      setIsLoading(false);

      setFormData({
        ...formData,
      });

      await delay(1000);
      setSubmitted(false);
    }

    setFormData({
      ...formData,
    });
  }

  async function post_and_navigate(newAccountData: AccountType) {
    const cache = new CacheService(
      newAccountData,
      PASSWORD_POST_RESPONSE,
      accountData
    );
    const passwordPostResponse = cache.getToCache();

    const intervalMilisecondsValue = intervalMiliseconds(passwordPostResponse);
    await delay(intervalMilisecondsValue);

    const commitPostResponse = await RegisterCommitPost(
      formData.usernamecode.value,
      passwordPostResponse?.access_token
    );
    console.log(commitPostResponse);

    cleanStringsObject(accountData);

    setAccountData({
      ...accountData,
      step: newAccountData.step,
    });

    navigate(
      ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.CONGRATULATIONS)
    );
  }

  function checkThisFormData() {
    cleanFormDataErrors(formData);

    const accountService: AccountService = new AccountService(t);
    accountService.checkCode(formData.usernamecode.value, "usernamecode");
  }

  function frontExceptionFunction(errorDescription: Array<string>) {
    formData[errorDescription[0] as keyof FormData].errors.add(
      errorDescription[1]
    );
  }
};

export default InsertUsernameCodePage;
