// Access the strings used on the SendRecoveryPasswordLink ans RecoveryPassword pages.
export const t_SendRecoveryPasswordLink = {
    title: "login.sendRecoveryPasswordLink.title",
    subtitle: "login.sendRecoveryPasswordLink.subtitle",
    text: "login.sendRecoveryPasswordLink.text",
    timeLabel: "login.sendRecoveryPasswordLink.timeLabel",
    linkLabel: "login.sendRecoveryPasswordLink.linkLabel",
  };

export const t_recoveryPassword = {
    title: "login.recoveryPassword.title",
    subtitle: "login.recoveryPassword.subtitle",
};