import React, { useState, ChangeEvent } from "react";


import { useNavigate } from "react-router-dom";

import AuthenticationForm from "@components/AuthenticationForm";
import FormField from "@components/Field";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ComposeURLWithGetQueryArguments, ComposeURLWithResponseParameters } from "@helpers/get";
import PagePaths from "@authien/helpers/constants/PagePaths";
import TimeForNewVerify from "@components/TimeForNewVerify";
import { useTranslation } from "react-i18next";
import { t_insertUsernameCode } from "@i18n/usualTexts/t_insertUsernameCode";
import {
  cleanFormDataErrors,
} from "@helpers/FormDataServices";
import { CacheService, PASSWORD_POST_RESPONSE } from "@services/cache/cacheService";
import { delay, intervalMiliseconds } from "@helpers/delay";
import { SendConfirmationCodePost, RegisterCommitPost } from "@authien/features/createaccount/service/AccountRequest";
import { showAuthError } from "../services/OAuth2ShowError";
import { setState } from "@authien/helpers/state_storage";
import { useOAuth2 } from "@authien/contexts/OAuth2Context";
import { AuthType, useAuthentication } from "@authien/contexts/AuthenticationContext";
import AccountService from "@authien/features/createaccount/service/AccountService";
import { VerifyEmailPost } from "../services/OAuth2Request";


interface FormData {
  usernamecode: {
    value: string;
    errors: Set<string>;
  };
}

const VerifyEmailPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { oauth2Data, state } = useOAuth2();
  const { authData, setAuthData} = useAuthentication();
  const [formData, setFormData] = useState<FormData>({
    usernamecode: {
      value: "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title={t(t_insertUsernameCode.title)}
      subtitle={t(t_insertUsernameCode.subtitle)}
      back={back}
      onSubmit={() => handleSubmit()}
      isLoading={isLoading}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="usernamecode"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.usernamecode.value}
            errors={formData.usernamecode.errors}
            submitted={submitted}
          >
            {t(t_insertUsernameCode.codePlaceholder)}
          </FormField>
        </Col>
      </Row>

      <TimeForNewVerify sendCodeEmail={sendCodeEmail} />
    </AuthenticationForm>
  );

  async function sendCodeEmail() {
    console.log("send code email");
    const cache = new CacheService(
      authData,
      PASSWORD_POST_RESPONSE,
      authData
    );
    const passwordPostResponse = cache.getToCache();
    console.log(passwordPostResponse);
    try {
      await SendConfirmationCodePost(passwordPostResponse?.access_token);
    } catch (error) {
      console.log(error)//window.location.reload();
    }
  }

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    formData[name as keyof FormData].value = value;
    setFormData({
      ...formData,
    });
  }

  function back() {
    setAuthData({
      ...authData,
      step: 2,
    });

    navigate(ComposeURLWithGetQueryArguments(PagePaths.LOGIN.PASSWORD));
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    const step = 6;

    const newAuthData: AuthType = {
      ...authData,
      step: step,
    };

    try {
      checkThisFormData()

      await post_and_navigate(newAuthData);
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      console.log(error)
      showAuthError("usernamecode", error, setServerErrorPopUp, formData.usernamecode.errors, t);
    } finally {
      setIsLoading(false);

      setFormData({
        ...formData,
      });

      await delay(1000);
      setSubmitted(false);
    }

    setFormData({
      ...formData,
    });
  }

  async function post_and_navigate(newAuthData: AuthType) {
    const cache = new CacheService(
        newAuthData,
      PASSWORD_POST_RESPONSE,
      authData
    );
    const passwordPostResponse = cache.getToCache();

    const intervalMilisecondsValue = intervalMiliseconds(passwordPostResponse);
    await delay(intervalMilisecondsValue);

    const commitPostResponse = await VerifyEmailPost(formData.usernamecode.value, oauth2Data, passwordPostResponse?.access_token);
    await delay(intervalMiliseconds(passwordPostResponse));
    console.log(commitPostResponse)
    setState(state);
    window.location.href =
      ComposeURLWithResponseParameters(commitPostResponse);
  }

  function checkThisFormData() {
    cleanFormDataErrors(formData);

    const accountService: AccountService = new AccountService(t);
    accountService.checkCode(formData.usernamecode.value, "usernamecode");
  }
};

export default VerifyEmailPage;
