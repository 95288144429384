/**
 * Copyright © 2017-2023 Knowledge for Decisions (K4D). All rights reserved.
 *
 * This code and associated materials are protected by copyright laws and international
 * treaties. Unauthorized use, reproduction, distribution, or modification of any content
 * from Knowledge for Decisions (K4D) is strictly prohibited. For inquiries regarding the
 * use or licensing of K4D's copyrighted materials, please contact us at contact@k4d.com.br.
 * "Knowledge for Decisions (K4D)" and the slogan "Using data to make precise decisions" are
 * registered trademarks of Knowledge for Decisions (K4D).
 */

import React, { useState, ChangeEvent } from "react";

import ExternalURL from "@authien/helpers/constants/ExtermalURL";
import PagePaths from "@authien/helpers/constants/PagePaths";

import { AuthType, useAuthentication } from "@contexts/AuthenticationContext";

import { NavigateFunction, useNavigate } from "react-router-dom";

import AuthenticationForm from "@components/AuthenticationForm";
import FormField from "@components/Field";
import TextButton from "@components/TextButton";
import Text from "@components/Text";

import OAuth2Service from "@authien/features/authentication/services/OAuth2Service";

import ExternalAuthentication from "@authien/layouts/ExternalAuthentication";

import { ComposeURLWithGetQueryArguments } from "@helpers/get";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import "bootstrap-icons/font/bootstrap-icons.css";
import { ThreeDots } from "react-loading-icons";

import { useOAuth2 } from "@contexts/OAuth2Context";
import { IdentityRequest } from "@features/authentication/services/OAuth2Request";
import { showAuthError } from "@features/authentication/services/OAuth2ShowError";
import { delay, intervalMiliseconds } from "@helpers/delay";
import { useTranslation } from "react-i18next";

import { t_identity } from "@i18n/usualTexts/t_identity";
import { t_help } from "@i18n/usualTexts/t_help";
import {
  cleanFormDataErrors,
  set_localStorage,
} from "@helpers/FormDataServices";
import {
  CacheService,
  IDENTITY_POST_RESPONSE,
} from "@services/cache/cacheService";
import NextButton from "@authien/components/button/Next";

interface FormData {
  username: {
    value: string;
    errors: Set<string>;
  };
}

const IdentityPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { oauth2Data, startAuthenticationRequestResponse } = useOAuth2();
  const { authData, setAuthData } = useAuthentication();
  const [formData, setFormData] = useState<FormData>({
    username: {
      value: authData.username ?? localStorage.getItem("username") ?? "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title={t(t_identity.title)}
      subtitle={t(t_identity.subtitle)}
      onSubmit={() => {
        handleSubmit();
      }}
      isLoading={isLoading}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="username"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.username.value}
            errors={formData.username.errors}
            submitted={submitted}
          >
            {t(t_identity.emailPlaceholder)}
          </FormField>
        </Col>
      </Row>

      <Row className="my-2 text-secondary py-1">
        <Col>
          <Text>{t(t_identity.guestModeText)}</Text>
          <TextButton url={ExternalURL.MORE_DETAILS}>
            {t(t_identity.guestModeLink)}
          </TextButton>
        </Col>
      </Row>

      <Row className="flex-grow-1 text-center text-lg-start mt-2 pt-2 align-items-center">
        <Col xs={6}>
          <TextButton
            variant="secondary"
            onClick={() => {
              toCreatePage(navigate);
            }}
          >
            {t(t_identity.createAccountButton)}
          </TextButton>
        </Col>
        <Col xs={6} className="text-end align-items-end">
          <NextButton isLoading={isLoading} />
        </Col>
      </Row>

      <Row>
        <Col>
          <Container className="p-0">
            <ExternalAuthentication />
          </Container>
        </Col>
      </Row>
    </AuthenticationForm>
  );

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    formData.username.value = value;
    setFormData({ ...formData });
    cleanFormDataErrors(formData);
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    const step = 2;

    const newAuthData: AuthType = {
      ...authData,
      username: formData.username.value,
      step: step,
    };

    const cache = new CacheService(
      newAuthData,
      IDENTITY_POST_RESPONSE,
      authData
    );
    try {
      const authenticationService: OAuth2Service = new OAuth2Service();
      authenticationService.checkIdentityFormat(formData.username.value);

      await postFormDatas_and_navigate(cache, newAuthData);
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      showAuthError(
        "username",
        error,
        setServerErrorPopUp,
        formData.username.errors,
        t
      );

      cache.postToCache(error);
    } finally {
      setIsLoading(false);
      set_localStorage(formData);

      setFormData({
        username: {
          ...formData.username,
        },
      });

      await delay(1000);
      setSubmitted(false);
    }
  }

  async function postFormDatas_and_navigate(
    cache: CacheService,
    newAuthData: AuthType
  ) {
    if (!cache.getToCache()) {
      const intervalMilisecondsValue = intervalMiliseconds(
        startAuthenticationRequestResponse
      );
      await delay(intervalMilisecondsValue);

      const identityPostResponse = await IdentityRequest(
        oauth2Data,
        formData.username.value,
        startAuthenticationRequestResponse?.access_token
      );
      console.log(identityPostResponse);

      cache.postToCache(identityPostResponse);
    }

    setAuthData(newAuthData);

    navigate(ComposeURLWithGetQueryArguments(PagePaths.LOGIN.PASSWORD));
  }
};

function toCreatePage(navigate: NavigateFunction) {
  navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.CREATEACCOUNT), {
    replace: true,
  });
};

export default IdentityPage;
