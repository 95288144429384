import React, { useState, ChangeEvent } from "react";

import { AccountType, useAccount } from "@contexts/AccountContext";

import { useNavigate } from "react-router-dom";

import AuthenticationForm from "@components/AuthenticationForm";
import FormField from "@components/Field";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import AccountService from "../service/AccountService";

import PagePaths from "@authien/helpers/constants/PagePaths";
import { ComposeURLWithGetQueryArguments } from "@helpers/get";

import "bootstrap-icons/font/bootstrap-icons.css";
import { useTranslation } from "react-i18next";
import { t_createPassword } from "@i18n/usualTexts/t_createPassword";
import { cleanFormDataErrors } from "@helpers/FormDataServices";
import { RegisterPasswordRequest } from "../service/AccountRequest";
import { delay, intervalMiliseconds } from "@helpers/delay";
import { showAuthError } from "../service/RegisterShowError";
import {
  CacheService,
  IDENTITY_POST_RESPONSE,
  PASSWORD_POST_RESPONSE,
} from "@services/cache/cacheService";

export interface FormData {
  password: {
    value: string;
    errors: Set<string>;
  };
  confirmPassword: {
    value: string;
    errors: Set<string>;
  };
}

const CreatePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { accountData, setAccountData } = useAccount();
  const [formData, setFormData] = useState<FormData>({
    password: {
      value: "",
      errors: new Set<string>(),
    },
    confirmPassword: {
      value: "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title={t(t_createPassword.title)}
      subtitle={t(t_createPassword.subtitle)}
      back={back}
      onSubmit={() => handleSubmit()}
      isLoading={isLoading}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="password"
            type="text"
            required={true}
            value={formData.password.value}
            errors={formData.password.errors}
            onChange={handleFormData}
            submitted={submitted}
          >
            {t(t_createPassword.passwordPlaceholder)}
          </FormField>
        </Col>
      </Row>

      <Row className="mt-4 px-0 m-0">
        <Col className="p-0 m-0">
          <FormField
            name="confirmPassword"
            nameconfirm="password"
            type="password"
            required={true}
            value={formData.confirmPassword.value}
            errors={formData.confirmPassword.errors}
            onChange={handleFormData}
            submitted={submitted}
          >
            {t(t_createPassword.confirmPasswordPlaceholder)}
          </FormField>
        </Col>
      </Row>
    </AuthenticationForm>
  );

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    formData[name as keyof FormData].value = value;
    setFormData({
      ...formData,
    });
  }

  function back() {
    setAccountData({
      ...accountData,
      step: 3,
    });

    navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.INSERTUSERNAME));
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    const step = 5;

    const newAccountData: AccountType = {
      ...accountData,
      step: step,
    };

    try {
      checkThisFormData();

      await post_and_navigate(newAccountData);
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      showAuthError(error, setServerErrorPopUp, frontExceptionFunction, t);
    } finally {
      setIsLoading(false);

      setFormData({
        ...formData,
      });

      await delay(1000);
      setSubmitted(false);
    }
  }

  async function post_and_navigate(newAccountData: AccountType) {
    const cache = new CacheService(
      newAccountData,
      PASSWORD_POST_RESPONSE,
      accountData
    );
    const identityPostResponse = cache.getToCache(IDENTITY_POST_RESPONSE);

    const intervalMilisecondsValue = intervalMiliseconds(identityPostResponse);
    await delay(intervalMilisecondsValue);

    const passwordPostResponse = await RegisterPasswordRequest(
      formData.password.value,
      formData.confirmPassword.value,
      identityPostResponse?.access_token
    );
    console.log(passwordPostResponse);

    cache.postToCache(passwordPostResponse);

    setAccountData(newAccountData);

    navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.VERIFYEMAIL));
  }

  function checkThisFormData() {
    cleanFormDataErrors(formData);

    const accountService: AccountService = new AccountService(t);
    accountService.checkPassword(formData.password.value, "password");
    accountService.checkConfirmPassword(
      formData.confirmPassword.value,
      formData.password.value,
      "confirmPassword"
    );
  }

  function frontExceptionFunction(errorDescription: Array<string>) {
    formData[errorDescription[0] as keyof FormData].errors.add(
      errorDescription[1]
    );
  }
};

export default CreatePasswordPage;
