import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import React, { useState, Fragment, ChangeEvent, useEffect } from "react";

import "../assets/styles/animation.css";
import { useTranslation } from "react-i18next";
import { t_help } from "@i18n/usualTexts/t_help";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { nextInput } from "@authien/helpers/nextInput";

export interface FormSelectProps {
  name: string;
  onChange: Function;
  value: string;
  immediateValidation?: boolean;
  errors?: Set<string>;
  required?: boolean;
  submitted?: boolean;
  options: Array<string>;
  toPresentErrors?: boolean;
  autofocus?: boolean;
  children: string;
}

interface PropsBox {
  value: string;
  selectedValue: string;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  onChange,
  value,
  immediateValidation = true,
  errors = new Set(),
  required = false,
  submitted = false,
  options,
  toPresentErrors = true,
  autofocus = false,
  children,
}) => {
  const { t } = useTranslation();
  const requiredErrorMessage = t(t_help.fieldOrSelect, {
    placeholder: children,
  });

  const [selectedValue, setSelectedValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const [query, setQuery] = useState(value);

  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (submitted && !selectedValue && required) {
      errors.clear();
      errors.add(requiredErrorMessage);
    } else if (value) {
      errors.delete(requiredErrorMessage);
    }

    const arrayFilteredOptions = query
      ? options.filter((option) => {
          let result =
            option.toLowerCase().substring(0, query.length) ===
            query.toLowerCase();
          if (!isNaN(+query)) {
            result = options.indexOf(option) + 1 === +query;
          }
          return result;
        })
      : options;

    if (filteredOptions !== arrayFilteredOptions) {
      setFilteredOptions(arrayFilteredOptions);
    }

    if (arrayFilteredOptions.length <= 1) {
      if (selectedValue !== arrayFilteredOptions[0])
        onSelect(arrayFilteredOptions[0]);
      setFilteredOptions(options);
    }
  }, [query, options]);

  function onSelect(value: string) {
    if (value) {
      setQuery(value);
      setSelectedValue(value);
      onChange(value, name);
      setIsFocused(false);
    }
  }

  const onBlur = () => {
    if (!isNaN(+query)) setQuery(filteredOptions[0]);
    setQuery(selectedValue);
    setIsFocused(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <Combobox value={selectedValue} onChange={onSelect}>
        <div className="form-floating">
          <ComboboxInput
            className={`form-control ${errors.size ? "is-invalid" : ""}`}
            placeholder={children}
            autoFocus={autofocus}
            required={required}
            data-immediate-validation={immediateValidation}
            name={name}
            id={name}
            onChange={(event) => setQuery(event.target.value)}
            autoComplete="off"
            onClick={() => setIsFocused(true)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => onBlur()}
            value={query}
          />
          <label className="form-label" htmlFor={name}>
            {children}
          </label>
        </div>
        <ComboboxOptions static={isFocused}>
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "0%",
              width: "100%",
              background: "white",
              border: "1px solid lightgray",
              borderRadius: "5px",
              listStyle: "none",
              maxHeight: "130px",
              overflowY: "auto",
              padding: "0",
              zIndex: "3",
              outline: "none",
            }}
          >
            <style>
              {`
                  ::-webkit-scrollbar {
                    width: 6px;
                  }
                  ::-webkit-scrollbar-track {
                    border-radius: 5px;
                  }
                  ::-webkit-scrollbar-thumb {
                    background: #D3D3D3;
                    border-radius: 5px;
                  }
                  ::-webkit-scrollbar-thumb:hover {
                    background: #888;
                  }
                `}
            </style>
            {filteredOptions.map((value: string) => (
              <BoxListOption
                key={value}
                value={value}
                selectedValue={selectedValue}
              />
            ))}
          </div>
        </ComboboxOptions>
      </Combobox>

      {errors.size > 0 &&
        toPresentErrors &&
        [...errors].map((error) => (
          <div
            key={error}
            data-testid="spanElement"
            className={
              "invalid-feedback d-block" + (submitted ? " shake-animation" : "")
            }
          >
            {error}
          </div>
        ))}
    </div>
  );

  function BoxListOption({ value, selectedValue }: PropsBox) {
    return (
      <ComboboxOption value={value}>
        {({ active }) => (
          <div
            style={{
              marginRight: "8px",
              cursor: "pointer",
              padding: "8px",
              width: "100%",
              backgroundColor: active
                ? "lightblue"
                : value === selectedValue
                ? "lightgray"
                : "transparent",
            }}
          >
            {value}
          </div>
        )}
      </ComboboxOption>
    );
  }
};

export default FormSelect;
