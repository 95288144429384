import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import React, { useEffect, useState } from "react";

import "../assets/styles/animation.css";
import { Col, Row } from "react-bootstrap";
import TextButton from "./TextButton";
import { useTranslation } from "react-i18next";
import { t_help } from "@i18n/usualTexts/t_help";

export interface Props {
  sendCodeEmail: Function;
  timeLabel?: string;
  linkLabel?: string;
}

const TimeForNewVerify: React.FC<Props> = ({ sendCodeEmail, timeLabel, linkLabel }) => {
  const intervalTime = 180;
  const [seconds, setSeconds] = useState(intervalTime - 1);
  const { t } = useTranslation();

  const timeLabelText = timeLabel ?? t(t_help.timeForNewCodeText);
  const linkLabelText = linkLabel?? t(t_help.timeForNewCodeLink);
  
  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    if (seconds === intervalTime) sendCodeEmail();

    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <Row className="flex-grow-1 text text-lg-start mt-4">
      <Col xs={10}>
        {seconds > 0 ? (
          <span className="link-secondary">
            {timeLabelText + ` (${seconds} seconds)`}
          </span>
        ) : (
          <TextButton onClick={() => setSeconds(intervalTime)}>
            {linkLabelText}
          </TextButton>
        )}
      </Col>
    </Row>
  );
};

export default TimeForNewVerify;
