import React, { useState, ChangeEvent } from "react";

import AuthenticationForm from "@components/AuthenticationForm";
import FormCheckBox from "@components/CheckBox";
import FormField from "@components/Field";
import FormSelect from "@components/Select";

import { dateFormat } from "../service/DateService";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
  cleanFormDataErrors,
  clearLocalStorage,
  set_localStorage,
} from "@helpers/FormDataServices";

import { DateType } from "@contexts/AccountContext";
import { delay } from "@helpers/delay";

import { useTranslation } from "react-i18next";
import { t_birthdayGender } from "@i18n/usualTexts/t_birthdayGender";
import { t_createAccount } from "@i18n/usualTexts/t_createAccount";

import NextButton from "@authien/components/button/Next";
import Text from "@components/Text";
import TextButton from "@components/TextButton";

import { countriesList } from "@authien/helpers/constants/CountriesList";
import { useOAuth2 } from "@authien/contexts/OAuth2Context";
import { jwtDecode } from "jwt-decode";
import { t_onboarding } from "@authien/i18n/usualTexts/t_onboarding";
import { CommitOnBoardingPost } from "../service/AccountRequest";

import AccountService from "../service/AccountService";
import { showAuthError } from "../service/RegisterShowError";
import { setState } from "@authien/helpers/state_storage";
import { GoogleAuthenticationCommitRequest } from "@authien/services/auth";

export interface FormData {
  birthday: {
    value: DateType;
    errors: Set<string>;
  };
  genderOption: {
    value: string;
    errors: Set<string>;
  };
  gender: {
    value: string;
    errors: Set<string>;
  };
  pronoun: {
    value: string;
    errors: Set<string>;
  };
  nationality: {
    value: string;
    errors: Set<string>;
  };
  acceptTerms: {
    value: string;
    errors: Set<string>;
  };
}

const OnBoardingPage: React.FC = () => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const { state } = useOAuth2();
  const user_name = JSON.parse(JSON.stringify(jwtDecode(state))).additional_claims.given_name;

  const [formData, setFormData] = useState<FormData>({
    birthday: {
      value: {
        day: localStorage.getItem("day") ?? "",
        month: localStorage.getItem("month") ?? "",
        year: localStorage.getItem("year") ?? "",
        value: localStorage.getItem("birthdate") ?? "",
      },
      errors: new Set<string>(),
    },
    genderOption: {
      value: localStorage.getItem("genderOption") ?? "",
      errors: new Set<string>(),
    },
    gender: {
      value: localStorage.getItem("gender") ?? "",
      errors: new Set<string>(),
    },
    pronoun: {
      value: localStorage.getItem("pronoun") ?? "",
      errors: new Set<string>(),
    },
    nationality: {
      value:
        localStorage.getItem("nationality") ?? "",
      errors: new Set<string>(),
    },
    acceptTerms: {
      value: "",
      errors: new Set<string>(),
    },
  });

  const monthSelectOptions = t(
    t_birthdayGender.monthPlaceholderSelectOptions
  ).split(",");
  const genderOptions = t(
    t_birthdayGender.genderPlaceholderSelectOptions
  ).split(",");
  const pronounOptions = t(
    t_birthdayGender.pronounPlaceholderSelectOptions
  ).split(",");

  return (
    <AuthenticationForm
      title={t(t_onboarding.title, { name: user_name })}
      subtitle={t(t_onboarding.subtitle)}
      onSubmit={() => handleSubmit()}
      isLoading={isLoading}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="day"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.birthday.value.day}
            submitted={submitted}
            errors={formData.birthday.errors}
            toPresentErrors={false}
          >
            {t(t_birthdayGender.dayPlaceholder)}
          </FormField>
        </Col>
        <Col>
          <FormSelect
            name="month"
            required={true}
            onChange={handleFormSelect}
            value={formData.birthday.value.month}
            submitted={submitted}
            options={monthSelectOptions}
            errors={formData.birthday.errors}
            toPresentErrors={false}
          >
            {t(t_birthdayGender.monthPlaceholderSelect)}
          </FormSelect>
        </Col>
        <Col>
          <FormField
            name="year"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.birthday.value.year}
            submitted={submitted}
            errors={formData.birthday.errors}
            toPresentErrors={false}
          >
            {t(t_birthdayGender.yearPlaceholder)}
          </FormField>
        </Col>
      </Row>

      <Row>
        <Col>
          {formData.birthday.errors.size > 0 &&
            [...formData.birthday.errors].map((error) => (
              <div
                key={error}
                data-testid="spanElement"
                className={"invalid-feedback d-block" + (submitted ? " shake-animation" : "")}
              >
                {error}
              </div>
            ))}
        </Col>
      </Row>

      <Row className="mt-4 px-0 m-0">
        <Col className="p-0 m-0">
          <FormSelect
            name="genderOption"
            required={true}
            onChange={handleFormSelect}
            value={formData.genderOption.value}
            errors={formData.genderOption.errors}
            submitted={submitted}
            options={genderOptions}
          >
            {t(t_birthdayGender.genderPlaceholderSelect)}
          </FormSelect>
        </Col>
      </Row>

      <Row>
        <Col>
          {formData.genderOption.value === genderOptions[2] && (
            <>
              <Row className="mt-4 px-0 m-0">
                <Col className="p-0 m-0">
                  <FormField
                    name="gender"
                    type="text"
                    required={true}
                    onChange={handleFormData}
                    value={formData.gender.value}
                    errors={formData.gender.errors}
                    submitted={submitted}
                  >
                    {t(t_birthdayGender.genderPlaceholder)}
                  </FormField>
                </Col>
              </Row>

              <Row className="mt-4 px-0 m-0">
                <Col className="p-0 m-0">
                  <FormSelect
                    name="pronoun"
                    required={true}
                    onChange={handleFormSelect}
                    value={formData.pronoun.value}
                    errors={formData.pronoun.errors}
                    submitted={submitted}
                    options={pronounOptions}
                  >
                    {t(t_birthdayGender.pronounPlaceholderSelect)}
                  </FormSelect>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>

      <Row className="mt-4 px-0 m-0">
        <Col className="p-0 m-0">
          <FormSelect
            name="nationality"
            required={true}
            onChange={handleFormSelect}
            value={formData.nationality.value}
            submitted={submitted}
            options={countriesList}
            errors={formData.nationality.errors}
          >
            {t(t_createAccount.nationalityPlaceholder)}
          </FormSelect>
        </Col>
      </Row>

      <Row className="mt-4 px-0 m-0">
        <Col className="p-0 m-0">
          <FormCheckBox
            name="acceptTerms"
            required={true}
            onChange={handleCheckBox}
            value={formData.acceptTerms.value}
            errors={formData.acceptTerms.errors}
            submitted={submitted}
          >
            <Text>{t(t_createAccount.serviceTermsCheck)}</Text>
            <TextButton>{t(t_createAccount.serviceTermsLink)}</TextButton>
          </FormCheckBox>
        </Col>
      </Row>

      <Row className="flex-grow-1 text-center text-lg-end mt-5">
        <Col className="text-end">
          <NextButton isLoading={isLoading} />
        </Col>
      </Row>
    </AuthenticationForm>
  );

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    if (!(name === "gender"))
      formData.birthday.value[name as keyof DateType] = value;
    else {
      formData[name as keyof FormData].value = value;
    }

    formData.birthday.value.value = dateFormat(
      formData.birthday.value,
      monthSelectOptions
    );

    setFormData({
      ...formData,
    });
    cleanFormDataErrors(formData);
  }

  function handleFormSelect(selectValue: string, selectName: string) {
    if (!(selectName.includes("gender") || selectName === "pronoun" || selectName === "nationality"))
      formData.birthday.value[selectName as keyof DateType] = selectValue;
    else {
      formData[selectName as keyof FormData].value = selectValue;
      if (selectValue === genderOptions[2]) {
        formData.gender.value = "";
        formData.pronoun.value = "";
      }
    }

    formData.birthday.value.value = dateFormat(
      formData.birthday.value,
      monthSelectOptions
    );

    setFormData({
      ...formData,
    });
    cleanFormDataErrors(formData);
  }

  function handleCheckBox(e: ChangeEvent<HTMLInputElement>){
    const { name } = e.target;

    formData[name as keyof FormData].value = formData[name as keyof FormData].value ? "" : "true";
    setFormData({
      ...formData,
    });
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    try {
      if (checkThisFormDatas())
      await postFormDatas_and_navigate({
        birthdate: formData.birthday.value.value,
        gender: formData.gender.value ? formData.gender.value : formData.genderOption.value,
        pronoun: formData.pronoun.value ? formData.pronoun.value : formData.genderOption.value,
        nationality: formData.nationality.value,
        terms: formData.acceptTerms.value === 'true',
      });
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      console.log(error);
      showAuthError(error, setServerErrorPopUp, frontExceptionFunction, t);
    } finally {
      setIsLoading(false);

      set_localStorage(formData);
      localStorage.setItem("day", formData.birthday.value.day);
      localStorage.setItem("month", formData.birthday.value.month);
      localStorage.setItem("year", formData.birthday.value.year);
      localStorage.setItem("birthdate", formData.birthday.value.value);

      setFormData({
        ...formData,
      });

      await delay(1000);
      setSubmitted(false);
    }
  }

  function checkThisFormDatas() {
    const accountService: AccountService = new AccountService(t);

    accountService.checkDate(
      formData.birthday.value,
      "birthday",
      monthSelectOptions
    );
    accountService.checkSelect(
      formData.genderOption.value,
      "genderOption",
      t(t_birthdayGender.genderPlaceholderSelect),
      genderOptions
    );

    if (formData.genderOption.value !== genderOptions[2]) {
      formData.gender.value = formData.genderOption.value;
      formData.pronoun.value = formData.genderOption.value;
    } else {
      accountService.checkName(
        formData.gender.value,
        "gender",
        t(t_birthdayGender.genderPlaceholderSelect)
      );
      accountService.checkSelect(
        formData.pronoun.value,
        "pronoun",
        t(t_birthdayGender.pronounPlaceholderSelect),
        pronounOptions
      );
    }

    accountService.checkSelect(
      formData.nationality.value,
      "nationality",
      t(t_createAccount.nationalityPlaceholder),
      countriesList
    );

    if (!formData.acceptTerms.value) {
      return false;
    }

    return true;
  }

  async function postFormDatas_and_navigate(
    onBoardingData: GoogleAuthenticationCommitRequest
  ) {

    const commitOnBoardingPostResponse = await CommitOnBoardingPost(
      onBoardingData,
      state
    );
    console.log(commitOnBoardingPostResponse);

    clearLocalStorage();

    setState(state);
    window.location.href = commitOnBoardingPostResponse.parameters?.redirect_uri
  }

  function frontExceptionFunction(errorDescription: Array<string>) {
    formData[errorDescription[0] as keyof FormData].errors.add(
      errorDescription[1]
    );
  }
};

export default OnBoardingPage;
